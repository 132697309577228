@font-face {
	font-family: 'BurlingameProCondensed';
	src: url('#{$font-path}/BurlingamePro-CondMedium.ttf')  format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'BurlingameProCondensed';
	src: url('#{$font-path}/BurlingamePro-CondSemiBold.ttf')  format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BurlingameProCondensed';
	src: url('#{$font-path}/BurlingamePro-CondBold.ttf')  format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'BurlingameProCondensed';
	src: url('#{$font-path}/BurlingamePro-CondXBold.ttf')  format('truetype');
	font-weight: 900;
	font-style: normal;
}