//////////
// Path //
//////////

$font-path: "../fonts";
$fa-font-path: "../fonts/webfonts";

/////////////////////////////
// Bulma Initial Variables //
/////////////////////////////

// Colors

$black:        hsl(0, 0%, 0%) !default;
$black-bis:    hsl(0, 0%, 5%) !default;
$black-ter:    hsl(0, 0%, 10%) !default;

$grey-darker:  hsl(0, 0%, 20%) !default;
$grey-dark:    hsl(0, 0%, 35%) !default;
$grey:         hsl(0, 0%, 50%) !default;
$grey-light:   hsl(0, 0%, 65%) !default;
$grey-lighter: hsl(0, 0%, 80%) !default;

$white-ter:    hsl(0, 0%, 90%) !default;
$white-bis:    hsl(0, 0%, 95%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(30,  100%, 50%) !default;
$yellow:       hsl(48,  100%, 67%) !default;
$green:        hsl(159, 56%,  38%) !default;
$turquoise:    hsl(171, 100%, 41%) !default;
$cyan:         hsl(204, 86%,  53%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$red:          hsl(348, 100%, 61%) !default;

// Typography

// $family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default
$family-sans-serif: "BurlingameProCondensed", sans-serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
// $gap: 64px !default
$gap: 30px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;

// Miscellaneous

$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags

$variable-columns: true !default;

/////////////////////////////
// Bulma Derived Variables //
/////////////////////////////

$primary: hsl(0, 77%, 51%) !default;
$secondary: hsl(0, 0, 68%) !default;

$info: $cyan !default;
$success: $green !default;
$warning: $orange !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey !default;

// Invert colors

$primary-invert: $white;
$secondary-invert: $white;
$warning-invert: $white !default;
$light-invert: $grey-dark !default;
$dark-invert: $white !default;

// General colors

$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors

$text: $grey-dark !default;

$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $blue !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// Typography

$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-4 !default;

// Lists and maps

$custom-colors: ("secondary": ($secondary, $secondary-invert)) !default;
$custom-shades: null !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 !default;

// Spinners

$spinner-color-ios: $grey !default;
$spinner-color-android: $grey !default;
$spinner-size-ios: 24px !default;
$spinner-size-android: 32px !default;
